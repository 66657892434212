
























































































































import { closeOverlayPermanent } from "@/utils/helpers";
import { formFieldPb, containerLeftPad, containerRightPad } from "@/utils/uiconstants";
import ListLoader from "@/components/shared/loaders/list-loader.vue";
import { mapMutations } from "vuex";
import farmersComputedMixin from "@/components/farmers/farmers-computed";
import { BaseButton as Button, ButtonActionPrimary } from '@/components/shared/buttons/index';
import CustomerEditForm from "./farmers-import-edit-form.vue";
import ConstantValues from '@/plugins/constantValues';

export default {
    name: "IntegratedCustomerImport",
    mixins: [farmersComputedMixin],
    components: {
        ButtonActionPrimary,
        ListLoader,
        CustomerEditForm,
        Button
    },
    props: ["intgrationType"],
    watch: {
        integrationContactSearchText(searchValue) {
            if (!searchValue) {
                this.tempIntegratedCustomers = [];
                this.getContactsForImport();
            }
            this.isSelectAllToggleChecked = false;
        },
    },
    data() {
        return {
            formFieldPb,
            containerLeftPad,
            containerRightPad,
            selectedContacts: [],
            isSelectAllToggleChecked: false,
            importCustomerLoader: false,
        };
    },
    mounted() {
        if(this.integrationContactSearchText) {
            this.integrationContactSearchText = '';
        }
    },
    computed: {
        someContactSelected() {
            return this.selectedContacts.length > 0 && !this.allContactSelected;
        },
        allContactSelected() {
            return this.selectedContacts.length == this.contactsIntegration.length;
        },
    },

    methods: {
        ...mapMutations({
            clearFarmersFilterOptions: "setClearFilterOptions",
            resetPaging: "resetPaging",
        }),
        closeImportIntegrateCustomersForm() {
            closeOverlayPermanent("importIntegratedCustomerFormOverlay");
            this.integratedCustomerImportInProgress = false;
            this.clearFarmersFilterOptions();
            this.resetPaging();
        },
        getContactsForImport() {
            this.clearFarmersFilterOptions();
            this.resetPaging();
            this.contactsIntegration = [];
            this.$store
                .dispatch("fnSetIntegrationContactsFilter", [
                    ConstantValues.defaultPageOffsetSize,
                    ConstantValues.defaultPageLimitSizeCustomer,
                ])
                .then((res) => {
                    this.loadedContactsCustomer = 0;
                    this.totalContacts = 0;
                    this.$store.dispatch("getIntegrationContacts", this.integrationType);
                    if (this.integrationContactSearchText == "") {
                        this.clearFarmersFilterOptions();
                    }
                });
        },
        searchIntegratedCustomerForInvite() {
            this.tempIntegratedCustomers = [];
            this.getContactsForImport();
        },
        proceedIntegratedImport() {
            this.clearFarmersFilterOptions()
            this.resetPaging()

            if((!this.allContactSelected && this.selectedContacts?.length > 0) || !this.isSelectAllToggleChecked) {
                this.selectedContacts.map(contact => {
                        this.$store.commit("setFilterOptions", [ConstantValues.queryIdentifiers, contact])
                })
            }

            this.importCustomerLoader = true;

            this.$store
                .dispatch("importContactsForIntegration")
                .then((res) => {
                    this.selectedContacts = [];
                    this.importCustomerLoader = false;
                    this.closeImportIntegrateCustomersForm();
                    this.farmersFilterList = [];
                    this.getFarmersListView();
                });
        },
        isValidUser(contact) {
            const isAddressValid = (contact.address == null) 
                                    || (contact.address?.addressLine1?.length > 0 
                                        && contact.address?.postalCode?.length > 0)

            const isNamesValid = contact.name?.length > 0 && contact.companyName?.length > 0

            return isNamesValid && isAddressValid
        },
        selectAllContact() {
            this.selectedContacts = [];
            if (this.isSelectAllToggleChecked) {
                this.contactsIntegration.forEach((contact) => {
                    if (this.isValidUser(contact)) {
                        this.selectedContacts.push(contact.id);
                    }
                })
            }
        },
        openCustomerUpdateForm(customer) {
            this.$store.commit("resetFarmerDetails");
            this.$store.commit("setFarmerDetails", customer);
            this.updateFormEnabled = true;
        },
        closeFarmerEditForm: function () {
            this.updateFormEnabled = false;
        },
        capitalizeText(text) {
            return text ? text.charAt(0).toUpperCase() + text.slice(1) : ''
        }
    },
};
